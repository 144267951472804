import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BaseComponent, BaseModule } from 'app/client/modules/_base/base';

import { IModal, IUser } from 'lc-interfaces';

@Component({
  selector: 'lc-user-modal',
  standalone: true,
  imports: [BaseModule],
  templateUrl: './user.modal.html',
  styleUrls: ['./user.modal.scss'],
})
export class UserModal extends BaseComponent {
  public user!: IUser;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public modalData: IModal<IUser>,
  ) {
    super();

    this.user = modalData.context.data;
  }
}
