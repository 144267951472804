<mat-list-item>
  <img matListItemAvatar [src]="user.photo_url" (click)="openUserModal()" />

  <div matListItemTitle (click)="openUserModal()">
    {{ user.full_name }}
  </div>

<!--  <div matListItemLine>hue mayo clan huyan</div>-->

  @if (!!action) {
    <div matListItemMeta (click)="onUserAction()">
      <button mat-icon-button>
        @if (action === 'request') {
          <mat-icon>person_add</mat-icon>
        } @else if (action === 'accept') {
          <mat-icon>person_check</mat-icon>
        } @else if (action === 'remove') {
          <mat-icon>person_remove</mat-icon>
        }
      </button>
    </div>
  }
</mat-list-item>
