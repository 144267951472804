import { Component, EventEmitter, Input, Output } from '@angular/core';

import WebApp from '@twa-dev/sdk';

import { BaseComponent, BaseModule } from 'app/client/modules/_base/base';

import { UserModal } from 'app/client/modules/user/modals/user/user.modal';

import { telegramUserUrl } from 'lc-helpers';

import { IUser } from 'lc-interfaces';

@Component({
  selector: 'lc-user-user-block',
  standalone: true,
  imports: [BaseModule],
  templateUrl: './user.block.html',
})
export class UserUserBlock extends BaseComponent {
  @Input()
  user!: IUser;

  @Input()
  action!: 'request' | 'accept' | 'remove';

  @Output()
  handleUserAction = new EventEmitter<{
    user: IUser;
    action: 'request' | 'accept' | 'remove';
  }>();

  onUserAction() {
    this.handleUserAction.emit({
      user: this.user,
      action: this.action,
    });
  }

  openUserModal() {
    this.openDialog<UserModal, IUser>(UserModal, {
      title: 'modals.user.title',
      data: this.user,
    });
  }

  openTelegramUser(user: IUser) {
    WebApp.openTelegramLink(telegramUserUrl(user));
  }
}
